import { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import useAuth from 'hooks/useAuth';
import { AppLogo, PasswordValidationList } from 'v2/src/core';
import RegisterSchema from 'v2/src/schema/register';
import { USER_TYPES } from 'v2/src/config/appConfig';
import { BtnOKCls } from 'v2/src/config/uiConfig';
import {
  fGetLocalSt, fSetLocalSt, fPostAuthSetData,
} from 'v2/src/lib/appUtils';
import {
  useRegisterMutation, useLoginMutation,
} from 'v2/src/lib/services/auth/authApi';
import {
  rSetUserCredentials, rSetUserDetails, //aSGetAuthUserData,
} from 'v2/src/lib/services/auth/authSlice';
import { useLazyUserDetailsQuery } from 'v2/src/lib/services/profileApi';
import { toast, ToastContainer } from "react-toastify";
//import { fGetCurrentTimezone } from 'v2/src/lib/dateTimeUtils';
//---------------------------------------------------------

export default function Register() {
  const { t: error_message } = useTranslation('error_message');
  const { t: common } = useTranslation('common');
  const { t: authentication } = useTranslation('authentication');
  const fDispatchSignIn = useDispatch();
  const fNavigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [fRegisterUser, { isLoading: pWaitRegUsr }] = useRegisterMutation();
  const [fLogin,] = useLoginMutation();
  const [fGetUserDetails] = useLazyUserDetailsQuery({ profileId: null, userType: null });

  const { register, handleSubmit, getValues, watch,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(RegisterSchema(error_message)),
    defaultValues: { UserType: USER_TYPES.patient, create_wallet: true },
  })
  const watchFields = watch(['Password']);//Required

  const attemptAutoLogin = async (eMail, pwd) => {
    if (eMail && pwd) {
      let signInResp, axCode, strMsg = '';
      try {
        signInResp = await fLogin({ email: eMail, password: pwd, });
        //console.log('signInResp', signInResp);
      } catch (excp) {
        console.log('excp', excp);
        //if(excp?.status) { axCode = excp.status; }
      }

      if (signInResp?.error) { signInResp = signInResp.error; }
      else if (signInResp?.data) { signInResp = signInResp.data; }

      if (signInResp?.status === true && signInResp?.data?.user?.id) {
        fPostAuthSetData(signInResp?.data, fDispatchSignIn, rSetUserCredentials,
          fGetUserDetails, rSetUserDetails, setAuth, auth);
        /*fDispatchSignIn(rSetUserCredentials(signInResp?.data));
        fSetAuthCookie(signInResp.data, setAuth, auth);
        //strMsg = signInResp?.message;
        */

        fNavigate('/', { replace: true });
      } else {
        fNavigate('/login', { replace: true });//state: {code: 'regSuccess'}
      }
    }
    return false;
  };

  const fRegisterHandler = useCallback(async (data) => {
    let regResp, signInResp, axCode, strMsg = '';
    //const frmInputs = new FormData();
    //frmInputs.append('email', data.email);
    const inParams = {
      first_name: data?.FirstName,
      //middle_name: data?.MiddleName,
      last_name: data?.LastName,
      email: data?.Email,
      //phone: data?.Phone,
      password: data?.Password,
      create_wallet: data?.create_wallet
    };
    if (data?.MiddleName?.length) { inParams.middle_name = data?.MiddleName; }

    try {
      regResp = await fRegisterUser(inParams);
    } catch (excp) {
      console.log('regErr', excp);
      //regResp = excp;
      //if(excp?.status) { axCode = excp.status; }
    }
    if (regResp?.error) { regResp = regResp.error; }
    else if (regResp?.data) { regResp = regResp.data; }
    console.log('regResp', { regResp, axCode });//return;

    if (regResp?.status === true && regResp?.data?.id) {
      const aLResp = await attemptAutoLogin(getValues().Email, getValues().Password);
    } else {//console.log('AA', regResp);
      if (!regResp?.data?.status) {
        const errMsg = regResp?.data?.message;
        const strMatchR = 'The email has already been taken.';
        //Failed to communicate with the external service: The email has already been taken.
        let errMsg2;// = regResp?.data?.errors?.email[0];
        if (Array.isArray(regResp?.data?.errors?.email) && regResp.data.errors.email?.length) {
          errMsg2 = regResp.data.errors.email[0];
        }
        if (errMsg2) {
          // Swal.fire({
          //   html: authentication('signup_400')
          // });
          toast.error(errMsg2)
          return;
        } else if (errMsg.endsWith(strMatchR)) {
          // Swal.fire({
          //   html: strMatchR
          // });
          toast.error(errMsg)
          return;
        } else {
          // Swal.fire({
          //   html: regResp?.data?.message || common('something_went_wrong')
          // });
          toast.error(regResp?.data?.message || common('something_went_wrong'))
          return;
        }
      } else {
        toast.error(regResp?.data?.message || common('something_went_wrong'))
        // Swal.fire({
        //   html: common('something_went_wrong')
        // });
        return;
      }
    }
  }, []);

  return (
    <div className="account-body accountbg">
      <div className="container">
        <div className="row vh-108 ">
          <div className="col-12 align-self-center" style={{ justifyContent: 'center' }}>
            <div className="auth-page">
              <div className="card auth-card shadow-lg m-3">
                <div className="card-body  ">
                  <div className="px-3">
                    <div style={{ textAlign: 'center' }}>
                      {/*<AppLogo style={{ maxHeight: '100px', height: 'auto', width: '100%', maxWidth: '170px' }} />*/}
                      <AppLogo style={{ maxHeight: '100px', height: '80px', width: 'auto', maxWidth: '100px' }} />
                    </div>

                    <div className="text-center auth-logo-text">
                      <h4 className="mt-0 mb-3 mt-5">{authentication('free_register')}</h4>
                    </div>

                    <form className="form-horizontal auth-form my-4" onSubmit={handleSubmit(fRegisterHandler)}>
                      <div className="form-group">
                        <label htmlFor="first_name">{common('first_name')}</label>
                        <small className="text-danger font-13"> *</small>
                        <div className="input-group">
                          <span className="auth-form-icon">
                            <i className="dripicons-user"></i>
                          </span>
                          <input
                            type="text"
                            id="first_name"
                            disabled={isSubmitting}
                            placeholder={common('enter_first_name')}
                            {...register('FirstName')}
                            className={`form-control ${Boolean(errors.FirstName) ? 'is-invalid' : ''}`}
                            tabIndex='1'
                          />
                        </div>
                        {errors.FirstName && <label className="text-danger">{error_message(errors.FirstName.message)}</label>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="last_name">{common('last_name')}</label>
                        <small className="text-danger font-13"> *</small>
                        <div className="input-group">
                          <span className="auth-form-icon">
                            <i className="dripicons-user"></i>
                          </span>
                          <input
                            type="text"
                            id="last_name"
                            disabled={isSubmitting}
                            placeholder={common('enter_last_name')}
                            {...register('LastName')}
                            className={`form-control ${Boolean(errors.LastName) ? 'is-invalid' : ''}`}
                            tabIndex='2'
                          />
                        </div>
                        {errors.LastName && <label className="text-danger">{error_message(errors.LastName.message)}</label>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">{common('email')}</label>
                        <small className="text-danger font-13"> *</small>
                        <div className="input-group">
                          <span className="auth-form-icon">
                            <i className="dripicons-mail"></i>
                          </span>
                          <input
                            type="email"
                            disabled={isSubmitting}
                            id="useremail"
                            placeholder={common('enter_email')}
                            {...register('Email')}
                            className={`form-control ${Boolean(errors.Email) ? 'is-invalid' : ''}`}
                            tabIndex='3'
                          />
                        </div>
                        {errors.Email && <label className="text-danger">{error_message(errors.Email.message)}</label>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="userpassword">{common('password')}</label>
                        <small className="text-danger font-13"> *</small>
                        <div className="input-group">
                          <span
                            className="auth-form-icon password-eye-icon"
                            onClick={(e) => {
                              setShowPassword(!showPassword)
                            }}
                          >
                            <i className={`mdi ${showPassword ? 'mdi-eye' : 'mdi-eye-off'}`}></i>
                          </span>
                          <input
                            type={`${showPassword ? 'text' : 'password'}`}
                            id="userpassword"
                            disabled={isSubmitting}
                            placeholder={common('enter_password')}
                            {...register('Password')}
                            className={`form-control ${Boolean(errors.Password) ? 'is-invalid' : ''}`}
                            tabIndex='4'
                          />
                        </div>
                        <PasswordValidationList password={getValues().Password} errors={errors} />
                      </div>

                      <div className="form-group">
                        <label htmlFor="conf_password">{common('confirm_password')}</label>
                        <small className="text-danger font-13"> *</small>
                        <div className="input-group">
                          <span
                            className="auth-form-icon password-eye-icon"
                            onClick={(e) => {
                              setShowConfirmPassword(!showConfirmPassword)
                            }}
                          >
                            <i className={`mdi ${showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'}`}></i>
                          </span>
                          <input
                            type={`${showConfirmPassword ? 'text' : 'password'}`}
                            id="conf_password"
                            disabled={isSubmitting}
                            placeholder={common('enter_confirm_password')}
                            {...register('ConfirmPassword')}
                            className={`form-control ${Boolean(errors.ConfirmPassword) ? 'is-invalid' : ''}`}
                            tabIndex='5'
                          />
                        </div>
                        {errors.ConfirmPassword && <label className="text-danger">{error_message(errors.ConfirmPassword.message)}</label>}
                      </div>
                      <div className="form-group row mt-4">
                        <div className="col-sm-12">
                          <div className="custom-control custom-checkbox checkbox-success">
                            <input type="checkbox" id="customSwitchSuccess" {...register('Terms')} className={`custom-control-input form-control ${Boolean(errors.Terms) ? 'is-invalid' : ''}`} />

                            <label className="custom-control-label text-muted" htmlFor="customSwitchSuccess">
                              {authentication('by_registering_you_agree')}
                              <Link to='https://niuhealth.com/privacy-policy/' target="_blank" className="text-primary text-decoration-none">
                                {' '}
                                {authentication('terms_of_use')}
                              </Link>
                              {' '}and{' '}
                              <Link to='/crypto-terms-and-condition' target="_blank" className="text-primary text-decoration-none">
                                {common('niu_wallet_terms_and_condition')}
                              </Link>
                            </label>
                            {errors.Terms && <label className="text-danger">{error_message(errors.Terms.message)}</label>}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="form-group mb-0 row">
                        <div className="col-12 mt-2">
                          <button type="submit"
                            className={BtnOKCls + (pWaitRegUsr ? ' disabled' : '')}
                          >
                            {pWaitRegUsr ? authentication('registering') : authentication('register')}
                            <i className="fas fa-sign-in-alt ml-1"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="m-3 text-center text-muted">
                    <p className="">
                      {authentication('already_have_account')}{' '}
                      <Link to="/login" className="text-primary ml-2">
                        {authentication('login')}
                      </Link>
                    </p>
                  </div>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "components/Footer";
import { useTranslation } from "react-i18next";
import {
  useLazyGetServiceQuery,
  usePostDateMutation,
  useSpecialityListQuery,
} from "v2/src/lib/services/provider/providerApi";
import {
  DEF_COUNTRY_ID,
} from "v2/src/config/appConfig";
import { Button, Modal } from "react-bootstrap";
import { usePostAppointmentMutation } from "v2/src/lib/services/nHAppointmentApi";
import { FaTimes } from "react-icons/fa";
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { fGetLocalSt, } from 'v2/src/lib/appUtils';
import { aSGetAuthUserData, } from 'v2/src/lib/services/auth/authSlice';
import { useUserProfileDetailQuery } from "v2/src/lib/services/profileApi";
import { useLocationCompQuery } from "v2/src/lib/services/locationApi";

export default function TellUsWhy() {
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [showDate, setShowDate] = useState('')
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [symptom, setSymptom] = useState("");
  const [selectedSymptom, setSelectedSymptom] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [symptomCharCount, setSymptomCharCount] = useState(0);
  const { t: patient_virtual_visit } = useTranslation("patient_virtual_visit");
  const [IsSpecialityId, setSpecialityId] = useState(false);
  const navigate = useNavigate();
  const maxLength = 150;
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [isService, setIsService] = useState('');
  const countryCookieName = "niuHCId";
  const selCountryId = fGetLocalSt(countryCookieName) || DEF_COUNTRY_ID;
  const [timezone, setTimezone] = useState();

  let arrTz = [];
  const {
    data: locApiData,
    isLoading: locWait,
    isFetching: locWait2,
  } = useLocationCompQuery(
    { countryId: selCountryId },
    { skip: selCountryId === null }
  );
  if (locApiData) {
    arrTz = locApiData?.timezones?.data || [];
  }

  const handleClose = () => {
    setShow(false)
  };
  const handleShow = () => setShow(true);

  let aUData = useSelector(aSGetAuthUserData) || fGetLocalSt('nHAUSl');
  const aUserProfileId = aUData?.details?.profileId;
  const arrComps = ["user", "role", "language", "addresses", "patient"];
  const {
    data: profileApiData,
    refetch: fRefetchProfile,
    isLoading: profWait,
    isFetching: profWait2,
    isError: profErr,
  } = useUserProfileDetailQuery(
    { profileId: aUserProfileId, arrComps },
    { skip: aUserProfileId === null, refetchOnMountOrArgChange: true }
  );
  const objProfData = profileApiData?.data;

  useEffect(() => {
    let timezone = arrTz.filter(item => item.id === objProfData?.timezone_id)[0]
    const Tz = "UTC" + timezone?.utc_offset + " " + timezone?.name
    setTimezone(Tz)
  }, [objProfData, arrTz])

  const triggerToast = () => {
    if (isService) {
      toast.error(isService);
    }
    if (success || error) {
      if (error) {
        toast.error(error);
      }
      if (success) {
        toast.success(success, {
          onClose: () => {
            navigate("/patient/appointments");
          },
          autoClose: 2000,
        });
      }
    }
  };

  useEffect(() => {
    triggerToast();
  }, [success, error, isService]);

  const { data: SpecialityListData } = useSpecialityListQuery();
  const [trigger, { data: serviceData = [], isFetching }] =
    useLazyGetServiceQuery();
  const [postDate] = usePostDateMutation();
  const [postAppointment] = usePostAppointmentMutation();

  useEffect(() => {
    if (IsSpecialityId) {
      trigger({ speciality_id: IsSpecialityId });
    }
  }, [IsSpecialityId]);

  const postDatefun = async (startDate, endDate) => {
    const res = await postDate({
      start_date: startDate,
      end_date: endDate,
      practice_id: serviceData?.data[0]?.practice_id,
      provider_id: serviceData?.data[0]?.provider_id,
    });
    let selectedDateKey;
    for (let dateKey in res?.data?.data) {
      selectedDateKey = dateKey;
    }
    setAvailableTimes(res?.data?.data[selectedDateKey]);
  };

  const handleDateChange = (date) => {
    const formattedDate = format(date, 'yyyy/MM/dd');
    setShowDate(formattedDate)
    setSelectedDate(formattedDate);
    if (date) {
      const formattedDate1 = format(date, 'yyyy-MM-dd');
      setSelectedDate(formattedDate1);
      postDatefun(formattedDate1, formattedDate1);
    }
  };

  function handleButtonClick(selected) {
    const currentSelected = new Set(selectedSymptom);

    if (currentSelected.has(selected)) return;
    setSymptomCharCount(symptom.length + selected.length);
    if (maxLength - symptomCharCount > selected.length) {
      setSelectedSymptom((prev) => [...prev, selected]);
      currentSelected.add(selected);
      if (symptom.trim().length <= 0) {
        setSymptom(selected);
      } else {
        setSymptom((prev) => `${prev}, ${selected}`);
      }
    }
  }

  function handleTextarea(e) {
    const val = e.target.value;
    setSymptom(val);
    setSymptomCharCount(e.target.value.length);
    if (val.length <= 0) {
      setSelectedSymptom([]);
    }
  }

  const handleSubmit = () => {
    if (!serviceData.data) {
      setIsService(serviceData?.message || patient_virtual_visit("no_service_found"))
    } else {
      handleShow();
    }
  };

  return (
    <div className="page-wrapper mt-0">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <h4 className="page-title"></h4>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <h3>{patient_virtual_visit("tell_us_why_youre_here")}</h3>
                  <h7>
                    {patient_virtual_visit("please_select_sepeciality_below")}
                  </h7>
                  <div className="d-flex flex-wrap justify-content-start align-items-center overflow-auto">
                    {SpecialityListData?.data.map((e) => (
                      <button
                        key={e?.id}
                        type="button"
                        className={`btn  btn-sm mr-1 text-nowrap ${IsSpecialityId
                          ? e?.id === IsSpecialityId
                            ? "btn-success"
                            : "btn-light"
                          : "btn-light"
                          }`}
                        style={{
                          boxShadow: "unset",
                          borderRadius: "15px",
                          margin: "3px",
                        }}
                        onClick={() => {
                          setIsService('');
                          setSpecialityId(e?.id);
                        }}
                      >
                        {e?.name}
                      </button>
                    ))}
                  </div>
                  <textarea
                    style={{ margin: "30px 0 0 0" }}
                    className="form-control"
                    rows="5"
                    id="message"
                    placeholder={patient_virtual_visit(
                      "add_a_reason_for_your_virtual_visit"
                    )}
                    value={symptom}
                    onChange={handleTextarea}
                    maxLength={maxLength}
                  ></textarea>
                  {maxLength - symptomCharCount}{" "}
                  {patient_virtual_visit("characters_remaining")}
                  <div className="d-flex flex-wrap justify-content-start align-items-center overflow-auto">
                    {[
                      patient_virtual_visit("cough"),
                      patient_virtual_visit("sore_throat"),
                      patient_virtual_visit("flu_symptoms"),
                      patient_virtual_visit("sinus_infection"),
                      patient_virtual_visit("ear_infection"),
                      patient_virtual_visit("skin_infection"),
                      patient_virtual_visit("upper_respiratory_infection"),
                      patient_virtual_visit("bone_fractures"),
                      patient_virtual_visit("muscles_sprain"),
                      patient_virtual_visit("urinary_issues"),
                      patient_virtual_visit("rash"),
                      patient_virtual_visit("headache_migraine"),
                      patient_virtual_visit("stomachache"),
                    ].map((e) => (
                      <button
                        key={e}
                        type="button"
                        className="btn btn-light btn-sm mr-1 text-nowrap"
                        style={{
                          boxShadow: "unset",
                          borderRadius: "15px",
                          margin: "3px",
                        }}
                        onClick={handleButtonClick.bind(this, e)}
                      >
                        {e}
                      </button>
                    ))}
                  </div>
                  <div className="wizard_btn">
                    <Modal show={show} onHide={handleClose} >
                      <Modal.Header>
                        <Modal.Title
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <b> {patient_virtual_visit("choose_your_date")} </b>
                          <div className="image-close-icon xfloat-right">
                            <button
                              type="button"
                              className="btn btn-danger btn-round waves-effect waves-light image-close-icon"
                              onClick={handleClose}
                            >
                              <FaTimes />
                            </button>
                          </div>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body style={{ minHeight: "380px", }}>
                        <form>
                          <div className="form-group">
                            <label htmlFor="date">
                              {patient_virtual_visit("select_date")}
                            </label>
                            <DatePicker
                              selected={showDate ? new Date(showDate) : null}
                              onChange={handleDateChange}
                              dateFormat="MM/dd/yyyy"
                              className="form-control"
                              minDate={new Date()}
                              placeholderText="MM/DD/YYYY"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="time">{patient_virtual_visit('select_time')}</label>
                            <select
                              className="form-control"
                              id="time"

                              onChange={(e) => {
                                console.log("-------------d", e.target.value);
                                setSelectedTime(
                                  availableTimes[e.target.value].start
                                );
                              }}
                              disabled={!availableTimes?.length}
                            >
                              <option value="">{patient_virtual_visit('choose_a_time')}</option>
                              {availableTimes?.map((time, index) => (
                                <option
                                  key={index}
                                  value={index}
                                  disabled={!time.is_available}
                                >
                                  {time?.start}
                                </option>
                              ))}
                            </select>
                          </div>


                          <div className="form-group">
                            <label htmlFor="timezone">{patient_virtual_visit('time_zone')}</label>
                            <input
                              type="text"
                              className="form-control"
                              id="timezone"
                              value={timezone}
                              disabled
                              placeholder="Enter Time Zone"
                            />
                          </div>
                        </form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="success"
                          disabled={!selectedDate || !selectedTime}
                          onClick={() => {
                            const params = {
                              provider_id: serviceData?.data[0]?.provider_id,
                              practice_id: serviceData?.data[0]?.practice_id,
                              service_id: serviceData?.data[0]?.id,
                              start_time: selectedDate + " " + selectedTime,
                              symptoms: symptom,
                            };
                            postAppointment(params)
                              .unwrap()
                              .then((data) => {
                                handleClose();
                                setSuccess(data.message);
                              }).catch(error => {
                                setError(error?.data?.message)
                              });
                          }}
                        >
                          {patient_virtual_visit("book_your_appointment")}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <button
                      type="button"
                      className="mb-1 mb-md-0 btn btn-success btn-round waves-effect waves-light figmaBigButton float-left"
                      onClick={handleSubmit}

                      disabled={!IsSpecialityId || !symptom}
                    >
                      {isSubmitting
                        ? patient_virtual_visit("please_wait")
                        : patient_virtual_visit("book_your_appointment")}
                    </button>

                    <Link to="..">
                      <button
                        type="button"
                        className="btn btn-danger btn-round waves-effect waves-light figmaBigButton float-bottom"
                      >
                        {patient_virtual_visit("cancel")}
                      </button>
                    </Link>
                    <p style={{ marginTop: "30px" }}>
                      {patient_virtual_visit(
                        "enjoy_our_video_content_while_you_wait"
                      )}
                    </p>
                  </div>

                </div>

              </div>

            </div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <img
                    src="../assets/images/getting-consultation-of-doctor.jpg"
                    className="steps_img"
                    alt=""
                  />

                  <div className="media">
                    <div className="media-body align-self-center">
                      <h3 className="mt-0 mb-1">
                        {patient_virtual_visit(
                          "how_to_get_the_most_out_of_your_visit"
                        )}
                      </h3>
                    </div>

                  </div>


                  <div className="pricingTable1 text-center">
                    <ul className="list-unstyled pricing-content-2 text-left py-2 border-0 mb-0">
                      <li>
                        {patient_virtual_visit("grant_camera_permission")}
                      </li>
                      <li>
                        {patient_virtual_visit(
                          "do_not_begin_a_virtual_visit_while_driving"
                        )}
                      </li>
                      <li>
                        {patient_virtual_visit("ensure_stable_connection")}
                      </li>
                      <li>{patient_virtual_visit("good_lighting")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

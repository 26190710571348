import { nHApi } from 'v2/src/lib/services/public/nHApi';

export const nHServiceApi = nHApi.injectEndpoints({
  endpoints: builder => ({
    postService: builder.mutation({
      query: ({ formData }) => {
        return {
          url: '/service',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['services']
    }),
    updateService: builder.mutation({
      query: ({ formData, id }) => {
        return {
          url: `/service/${id}`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    getServiceList: builder.query({
      query: ({ page }) => {
        return {
          url: `/services`,
          params: {
            per_page: 10,
            page
          }
        };
      },
      providesTags: ['services']
    }),
    getSingleService: builder.query({
      query: ({ id }) => {
        console.log("getSingleService called", id)
        return {
          url: `/service/${id}`,
        };
      },
    }),

  }),
});

export const {
  usePostServiceMutation,
  useUpdateServiceMutation,
  useLazyGetServiceListQuery,
  useLazyGetSingleServiceQuery,
} = nHServiceApi;
import { useSelector } from "react-redux";
import { aSGetAuthUserData } from 'v2/src/lib/services/auth/authSlice';
import {
  NS_APP_BASE_URL, DEF_LANGUAGE_CODE, USER_TYPES,
  //DEF_COUNTRY_ID, DEF_COUNTRY_CODE, DEF_COUNTRY_NAME,
} from 'v2/src/config/appConfig';
import { CharOK, CharErr } from 'v2/src/config/uiConfig';
import Cookies from "js-cookie";
import { fReverseStr, fBase64Decode } from 'v2/src/lib/strUtils';
import { fGetArrTimeZonesMoment } from 'v2/src/lib/dateTimeUtils';
//import { fArgsToQStr, } from 'v2/src/lib/netUtils';
import notificationSound1 from 'v2/src/assets/mult/notification01.mp3';
//import appTimezones from 'v2/src/data/timezones.json';
//---------------------------------------------------------

const audio = new Audio(notificationSound1);
export const fPlayNotificationSound = () => {
  if (audio && typeof audio.play === 'function') {
    audio.play().catch((error) => {
      console.error('Audio play failed:', error);
    });
  } else {
    console.warn('Audio object or play method does not exist');
  }
};

export const fCheckPassCondition = (Condition) => {
  if (Condition === true) { return CharOK; }
  else if (Condition === false) { return CharErr; }
};

/*export const XfSetAuthCookie = ({
  email, name, accessToken, accessToken2,
  userId, userType, isLoggedIn,
}) => {
  Cookies.set('email', email);
  Cookies.set('name', name);
  Cookies.set('accessToken', accessToken);
  Cookies.set('accessToken2', accessToken2);
  Cookies.set('accessTokenNS', accessToken);
  Cookies.set('accessTokenNH', accessToken2);
  Cookies.set('userId', userId);
  Cookies.set('userType', userType);
  Cookies.set('isLoggedIn', isLoggedIn);
};*/

export const fGetCookie = (cName) => { Cookies.get(cName); };
export const fSetCookie = (cName, cVal) => { Cookies.set(cName, cVal); };
export const fSetAuthCookie = (aData, cBFx = null, cbBData = {}) => {
  //console.log('fSetAuthCookie', aData);
  const accessTokenNS = aData?.tokens?.social?.token;
  const accessTokenNH = aData?.tokens?.health?.token;
  const userType = aData?.profiles?.provider?.profile_id ?
    USER_TYPES.provider : USER_TYPES.patient;
  const isLoggedIn = aData?.user?.id ? true : false;
  const email = aData?.user?.email;
  const name = aData?.user?.first_name;
  const userId = aData?.user?.external_user_id;

  Cookies.set('email', email);
  Cookies.set('name', name);
  Cookies.set('userId', userId);
  Cookies.set('userType', userType);
  Cookies.set('isLoggedIn', isLoggedIn);
  Cookies.set('accessToken', accessTokenNS);//OldRelic..
  //Cookies.set('accessToken2', accessTokenNH);
  Cookies.set('accessTokenNS', accessTokenNS);
  Cookies.set('accessTokenNH', accessTokenNH);

  if (cBFx) {
    cBFx({
      ...cbBData, email, name, accessToken: accessTokenNS,
      userId, userType, isLoggedIn,
    });
  }
};
export const fInvalidateAuthCookie = () => {
  Cookies.remove('email');
  Cookies.remove('name');
  Cookies.remove('userId');
  Cookies.remove('userType');
  Cookies.remove('isLoggedIn');
  Cookies.remove('accessToken');
  Cookies.remove('accessTokenNH');

  Cookies.remove('has_insurance');
  Cookies.remove('has_subscribed');
};
export const fGetLocalSt = (kName) => {
  //try {
  return JSON.parse(localStorage.getItem(kName));
};
export const fSetLocalSt = (kName, kVal = {}) => {
  //try {
  localStorage.setItem(kName, JSON.stringify(kVal));
  //} catch { return false; }
};
export const fClearLocalStorageNH = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const fDecodeAppCommToken = (strCT) => {
  //try-catch
  return JSON.parse(fBase64Decode(fReverseStr(strCT)));
  //let res = strCT.split('').reverse().join('');
  //return JSON.parse(atob(res));
};

export const fGetAUserInf = () => {
  return useSelector(aSGetAuthUserData) || fGetLocalSt('nHAUSl');
};
export const fDetectUserType = (uProfiles) => {
  let userType, profileId;
  if (uProfiles) {
    if (uProfiles?.provider?.profile_id) {
      userType = USER_TYPES.provider;
      profileId = uProfiles.provider.profile_id;
    } else if (uProfiles?.support?.profile_id) {
      userType = USER_TYPES?.support || null;
      profileId = uProfiles.support.profile_id;
    } else if (uProfiles?.driver?.profile_id) {
      userType = USER_TYPES?.driver || null;
      profileId = uProfiles.driver.profile_id;
    } else if (uProfiles?.admin?.profile_id) {
      userType = USER_TYPES?.admin || null;
      profileId = uProfiles.admin.profile_id;
    } else {
      userType = USER_TYPES.patient;
      profileId = uProfiles?.patient.profile_id;
    }
    userType = userType ?? USER_TYPES.patient;

    return { userType, profileId };
  }
};

//fPostAuthSetData(signInResp?.data, fDispatch, rSetUserCredentials,
//  fGetUserDetails, rSetUserDetails, setAuth, auth);
export const fPostAuthSetData = async (authData, refDispFx, fDispInFx,
  aFxFetchAddUsrData, fDispAddDataInFx, fHSetAuth, hAuth
) => {
  let pAResp, profileResp;
  const authUserId = authData?.user?.id;
  const { profileId, userType } = fDetectUserType(authData?.profiles);
  //console.log('authUserId', authUserId);

  //---------------------------
  const fFetchUserProfileData = async (aUserId, aUProfId, uType,
    aFxFetchUsrData, refDispFx2, fDispIn2Fx
  ) => {
    let pResp;
    //if(!aUserId) { aUserId = rsAUData?.userIdNH; }

    if (aUserId && aUProfId) {
      //pResp = await aFxFetchUsrData({ userId: aUserId });
      pResp = await aFxFetchUsrData({ profileId: aUProfId, userType: uType });
    }//console.log('profResp_'+aUserId+'_'+aUProfId, pResp);
    if (pResp?.error) { pResp = pResp.error; }
    else if (pResp?.data && pResp.data?.data) {
      pResp = pResp.data.data;
      refDispFx2(fDispIn2Fx(pResp));
      //fSetLocalSt()
      return true;
    }
    return false;// pResp;
  };
  //---------------------------

  if (authUserId) {
    refDispFx(fDispInFx(authData));
    //fSetLocalSt();
    fSetAuthCookie(authData, fHSetAuth, hAuth);
    //strMsg = signInResp?.message;

    profileResp = await fFetchUserProfileData(authUserId, profileId, userType,
      aFxFetchAddUsrData, refDispFx, fDispAddDataInFx);
    //console.log('profileResp', profileResp);
  }
  return pAResp;
};

export const fRedirectToNiuSocial = async (aUrl, args = {}) => {
  //let rUrl = (aUrl || NS_APP_BASE_URL) + fArgsToQStr(args);
  //window.location.replace(rUrl);
  const accessToken = Cookies.get('accessToken') || '';
  const email = Cookies.get('email') || '';
  const userId = Cookies.get('userId') || '';
  window.location.href = NS_APP_BASE_URL + '?niuhcauth=' + accessToken + '&email=' + email + '&userId=' + userId;
};

export const fGetArrTimeZones = (countryId = null, filterCountryCode = null) => {
  //appTimezones
  let resp = countryId === 232 ?//let resp = filterCountryCode === 'US' ?
    [
      //{tzId: 1, utcOffsetMinute: -720, tzCode: 'AoE', title: 'Anywhere on Earth'},
      { tzId: 2, utcOffsetMinute: -240, tzCode: 'AST', title: 'Atlantic Standard Time' },
      { tzId: 3, utcOffsetMinute: -300, tzCode: 'EST', title: 'Eastern Standard Time' },
      { tzId: 4, utcOffsetMinute: -360, tzCode: 'CST', title: 'Central Standard Time' },
      { tzId: 5, utcOffsetMinute: -420, tzCode: 'MST', title: 'Mountain Standard Time' },
      { tzId: 6, utcOffsetMinute: -480, tzCode: 'PST', title: 'Pacific Standard Time' },
      { tzId: 7, utcOffsetMinute: -540, tzCode: 'AKST', title: 'Alaska Standard Time' },
      { tzId: 8, utcOffsetMinute: -600, tzCode: 'HST', title: 'Hawaii-Aleutian Standard Time' },
      { tzId: 9, utcOffsetMinute: -660, tzCode: 'SST', title: 'Samoa Standard Time' },
      { tzId: 10, utcOffsetMinute: 600, tzCode: 'ChST', title: 'Chamorro Standard Time' },
      { tzId: 11, utcOffsetMinute: 720, tzCode: 'WIST', title: 'Wake Island Time Zone' },
    ] : fGetArrTimeZonesMoment(filterCountryCode);

  if (countryId === 232) {
    //resp.forEach(tzInf => { return tzInf['countryId'] = countryId; });
    //resp = resp.map(tzInf => { return tzInf['countryId'] = countryId; });
    //resp.map(tzInf => { return {...tzInf, countryId: countryId} });
    for (const objTz of resp) { objTz['countryId'] = countryId; }
  }

  return resp;
};
//export const fGetProvinceTimezoneMap = () => {};
export const fGetCountryProvinces = (countryId) => {
  return countryId === 232 ?
    [//US has SummerTime DST
      {
        provinceId: 2, code: 'AL', province: 'Alabama', primaryTz: 'CST',
        secondaryTz: null, hasDst: false
      },
      {
        provinceId: 3, code: 'AK', province: 'Alaska', primaryTz: 'AKST',
        secondaryTz: 'HST', hasDst: false
      },
      {
        provinceId: 4, code: 'AZ', province: 'Arizona', primaryTz: 'MST',
        secondaryTz: null, hasDst: 'mostlyNo'
      },//*
      {
        provinceId: 5, code: 'AR', province: 'Arkansas', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 6, code: 'CA', province: 'California', primaryTz: 'PST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 7, code: 'CO', province: 'Colorado', primaryTz: 'MST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 8, code: 'CT', province: 'Connecticut', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 9, code: 'DE', province: 'Delaware', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 10, code: 'FL', province: 'Florida', primaryTz: 'EST',
        secondaryTz: 'CST', hasDst: true
      },
      {
        provinceId: 11, code: 'GA', province: 'Georgia', primaryTz: 'EST',
        secondaryTz: null, hasDst: false
      },
      {
        provinceId: 12, code: 'HI', province: 'Hawaii', primaryTz: 'HST',
        secondaryTz: null, hasDst: false
      },
      {
        provinceId: 13, code: 'ID', province: 'Idaho', primaryTz: 'MST',
        secondaryTz: 'PST', hasDst: true
      },
      {
        provinceId: 14, code: 'IL', province: 'Illinois', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 15, code: 'IN', province: 'Indiana', primaryTz: 'EST',
        secondaryTz: 'CST', hasDst: true
      },
      {
        provinceId: 16, code: 'IA', province: 'Iowa', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 17, code: 'KS', province: 'Kansas', primaryTz: 'CST',
        secondaryTz: 'MST', hasDst: true
      },
      {
        provinceId: 18, code: 'KY', province: 'Kentucky', primaryTz: 'CST',
        secondaryTz: 'EST', hasDst: true
      },
      {
        provinceId: 19, code: 'LA', province: 'Louisiana', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 20, code: 'ME', province: 'Maine', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 21, code: 'MD', province: 'Maryland', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 22, code: 'MA', province: 'Massachusetts', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 23, code: 'MI', province: 'Michigan', primaryTz: 'EST',
        secondaryTz: 'CST', hasDst: true
      },
      {
        provinceId: 24, code: 'MN', province: 'Minnesota', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 25, code: 'MS', province: 'Mississippi', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 26, code: 'MO', province: 'Missouri', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 27, code: 'MT', province: 'Montana', primaryTz: 'MST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 28, code: 'NE', province: 'Nebraska', primaryTz: 'CST',
        secondaryTz: 'MST', hasDst: true
      },
      {
        provinceId: 29, code: 'NV', province: 'Nevada', primaryTz: 'PST',
        secondaryTz: 'MST', hasDst: true
      },
      {
        provinceId: 30, code: 'NH', province: 'New Hampshire', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 31, code: 'NJ', province: 'New Jersey', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 32, code: 'NM', province: 'New Mexico', primaryTz: 'MST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 33, code: 'NY', province: 'New York', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 34, code: 'NC', province: 'North Carolina', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 35, code: 'ND', province: 'North Dakota', primaryTz: 'CST',
        secondaryTz: 'MST', hasDst: true
      },
      {
        provinceId: 36, code: 'OH', province: 'Ohio', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 37, code: 'OK', province: 'Oklahoma', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 38, code: 'OR', province: 'Oregon', primaryTz: 'PST',
        secondaryTz: 'MST', hasDst: true
      },
      {
        provinceId: 39, code: 'PA', province: 'Pennsylvania', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 40, code: 'RI', province: 'Rhode Island', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 41, code: 'SC', province: 'South Carolina', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 42, code: 'SD', province: 'South Dakota', primaryTz: 'CST',
        secondaryTz: 'MST', hasDst: true
      },
      {
        provinceId: 43, code: 'TN', province: 'Tennessee', primaryTz: 'CST',
        secondaryTz: 'EST', hasDst: true
      },
      {
        provinceId: 44, code: 'TX', province: 'Texas', primaryTz: 'CST',
        secondaryTz: 'MST', hasDst: true
      },
      {
        provinceId: 45, code: 'UT', province: 'Utah', primaryTz: 'MST	',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 46, code: 'VT', province: 'Vermont', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 47, code: 'VA', province: 'Virginia', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 48, code: 'WA', province: 'Washington', primaryTz: 'PST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 49, code: 'WV', province: 'West Virginia', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 50, code: 'WI', province: 'Wisconsin', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 51, code: 'WY', province: 'Wyoming', primaryTz: 'MST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 52, code: 'DC', province: 'Washington, D.C.', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 53, code: 'AS', province: 'American Samoa', primaryTz: 'SST',
        secondaryTz: null, hasDst: false
      },
      {
        provinceId: 54, code: 'GU', province: 'Guam', primaryTz: 'ChST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 55, code: 'MP', province: 'Northern Mariana Islands', primaryTz: 'ChST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 56, code: 'PR', province: 'Puerto Rico', primaryTz: 'AST',
        secondaryTz: null, hasDst: false
      },
      {
        provinceId: 57, code: 'USVI', province: 'United States Virgin Islands',
        primaryTz: 'AST', secondaryTz: null, hasDst: false
      }
    ] : [];
};
/*export const XfGetCountryProvinces = (countryId) => {
  return countryId === 232 ?
  [
    {province_id: 2, province: 'Alabama'},
    {province_id: 3, province: 'Alaska'},
    {province_id: 4, province: 'Arizona'},
    {province_id: 5, province: 'Arkansas'},
    {province_id: 6, province: 'California'},
    {province_id: 7, province: 'Colorado'},
    {province_id: 8, province: 'Connecticut'},
    {province_id: 9, province: 'Delaware'},
    {province_id: 10,	province: 'Florida'},
    {province_id: 11,	province: 'Georgia'},
    {province_id: 12,	province: 'Hawaii'},
    {province_id: 13,	province: 'Idaho'},
    {province_id: 14,	province: 'Illinois'},
    {province_id: 15,	province: 'Indiana'},
    {province_id: 16,	province: 'Iowa'},
    {province_id: 17,	province: 'Kansas'},
    {province_id: 18,	province: 'Kentucky'},
    {province_id: 19,	province: 'Louisiana'},
    {province_id: 20,	province: 'Maine'},
    {province_id: 21,	province: 'Maryland'},
    {province_id: 22,	province: 'Massachusetts'},
    {province_id: 23,	province: 'Michigan'},
    {province_id: 24,	province: 'Minnesota'},
    {province_id: 25,	province: 'Mississippi'},
    {province_id: 26,	province: 'Missouri'},
    {province_id: 27,	province: 'Montana'},
    {province_id: 28,	province: 'Nebraska'},
    {province_id: 29,	province: 'Nevada'},
    {province_id: 30,	province: 'New Hampshire'},
    {province_id: 31,	province: 'New Jersey'},
    {province_id: 32,	province: 'New Mexico'},
    {province_id: 33,	province: 'New York'},
    {province_id: 34,	province: 'North Carolina'},
    {province_id: 35,	province: 'North Dakota'},
    {province_id: 36,	province: 'Ohio'},
    {province_id: 37,	province: 'Oklahoma'},
    {province_id: 38,	province: 'Oregon'},
    {province_id: 39,	province: 'Pennsylvania'},
    {province_id: 40,	province: 'Rhode Island'},
    {province_id: 41,	province: 'South Carolina'},
    {province_id: 42,	province: 'South Dakota'},
    {province_id: 43,	province: 'Tennessee'},
    {province_id: 44,	province: 'Texas'},
    {province_id: 45,	province: 'Utah'},
    {province_id: 46,	province: 'Vermont'},
    {province_id: 47,	province: 'Virginia'},
    {province_id: 48,	province: 'Washington'},
    {province_id: 49,	province: 'West Virginia'},
    {province_id: 50,	province: 'Wisconsin'},
    {province_id: 51,	province: 'Wyoming'},
    //{province_id: 52,	province: 'Washington, D.C.'},
    ] : [];
};*/

export const fParseGglPlaces = (pl, apiKey = null, langCode = DEF_LANGUAGE_CODE) => {
  let resp, tzApiUrl;

  if (pl?.address_components && pl.address_components?.length) {
    resp = {};
    resp['selAddr'] = pl?.formatted_address;

    pl.address_components.forEach((pC) => {
      if (pC?.types?.[0]) {
        //City may not exist but postal_town or locality
        switch (pC.types[0]) {
          case 'street_number': resp['streetNum'] = pC; break;
          case 'subpremise': resp['complex'] = pC; break;
          case 'route': resp['route'] = pC; break;
          case 'neighborhood': resp['neighborhood'] = pC; break;
          case 'sublocality_level_1': resp['sublocality'] = pC; break;
          case 'sublocality': resp['sublocality'] = pC; break;
          //case 'locality': resp['locality'] = pC; break;
          case 'locality': resp['city'] = pC; break;
          case 'postal_town': resp['township'] = pC; break;
          case 'administrative_area_level_3': resp['township'] = pC; break;
          case 'administrative_area_level_2': resp['district'] = pC; break;
          case 'administrative_area_level_1': resp['province'] = pC; break;
          case 'country': resp['country'] = pC; break;
          case 'postal_code': resp['zip'] = pC; break;
        }
      }
    });
    resp['placeId'] = pl?.place_id;
    resp['utcOffSet'] = pl?.utc_offset_minutes;//utc_offset
    const plLoc = pl?.geometry?.location;
    if (plLoc) {
      tzApiUrl = 'https://maps.googleapis.com/maps/api/timezone/json' +
        '?location=' + encodeURIComponent((plLoc.lat()) + ',' + (plLoc.lng()));
      if (apiKey) { tzApiUrl += '&key=' + apiKey; }
      if (langCode) { tzApiUrl += '&language=' + langCode; }
      tzApiUrl += '&timestamp=' + (Date.now());//1331161200';
    }
    resp['tzApiUrl'] = tzApiUrl;
  }
  return resp;
};
export const fFormatGglAddressComps = (addrCmp) => {
  let resp;
  if (addrCmp?.province?.long_name?.length) {
    resp = { nAddr1: [], nAddr2: [] };
    //streetNum, route, neighborhood
    if (addrCmp?.streetNum?.long_name) {
      resp.nAddr1.push(addrCmp.streetNum.long_name);
    }
    if (addrCmp?.complex?.long_name) {
      resp.nAddr1.push(addrCmp.complex.long_name);
    }
    if (addrCmp?.route?.long_name) {
      resp.nAddr1.push(addrCmp.route.long_name);
    }
    resp.nAddr1 = resp.nAddr1.join(', ').trim();

    //sublocality || township, locality
    if (addrCmp?.neighborhood?.long_name) {
      resp.nAddr2.push(addrCmp.neighborhood.long_name);
    }
    if (addrCmp?.sublocality?.long_name) {
      resp.nAddr2.push(addrCmp.sublocality.long_name);
    } else if (addrCmp?.township?.long_name) {
      resp.nAddr2.push(addrCmp.township.long_name);
    }
    //if(addrCmp?.locality?.long_name) {
    //  resp.nAddr2.push(addrCmp.locality.long_name);
    //}
    resp.nAddr2 = resp.nAddr2.join(', ').trim();

    if (!resp.nAddr1.length) {
      resp.nAddr1 = resp.nAddr2; resp.nAddr2 = '';
    }

    //resp['nCity'] = addrCmp?.city?.long_name || addrCmp?.township?.long_name || '';
    resp['nCity'] = addrCmp?.city?.long_name || '';
    resp['nProvince'] = addrCmp?.province?.long_name || '';
    resp['nProvinceCode'] = addrCmp?.province?.short_name || '';
    resp['nCountry'] = addrCmp?.country?.long_name;
    resp['nZip'] = addrCmp?.zip?.long_name || '';
    resp['nTZ'] = addrCmp?.utcOffSet;

    if (resp.nCity.length) {
      resp.nCity = resp.nCity.replace(' County', '').replace(' county', '');
    }
  }
  return resp;
};

export const loadScript = (src, id) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve();
      return;
    }
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
}
